import ReactGA4 from "react-ga4";

const GA4_MEASUREMENT_ID = process.env.NODE_ENV === 'production' ? 'G-T1N4MR5N2M' : 'G-9999999999';

export const initGA = () => {
    ReactGA4.initialize([
        {
            trackingId: GA4_MEASUREMENT_ID,
            debugMode: process.env.NODE_ENV !== 'production'
        }
    ]);
}

/**
 * Track page views.
 */
export const trackPageView = (path) => {
    ReactGA4.send({ hitType: "pageview", page: path });
}

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action 
 * @param {string} label 
 */
export const Event = ({ category, action }) => {
    ReactGA4.event({
        category,
        action
    });
};

export const gaCategory = {
    // Categories are feature pages or components
    USER_INTEREST: "User interest",
    EXPLORE_INFO: "Explore info",
    NAV_BAR: "Nav bar",
    DEMO: "Demo"
}

export const gaAction = {
    HOME_MAIN_ACTION_BUTTON: 'Home page main action button clicked',
    ABOUT_DEMO_ACTION_BUTTON: 'About page try demo button clicked',
    DEMO_GENERATE_BUTTON: 'Demo generate button clicked',
    NAV_DEMO_BUTTON: 'Nav try demo button clicked',
}