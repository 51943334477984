import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter, useHistory } from 'react-router-dom';

import { logOutUser } from '../../store/actions/authActions';
import './styles.css';

import { isMobile } from "react-device-detect";
//import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
//import AppBar from '@material-ui/core/AppBar';
//import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Logo from '../../images/Oroara_logo.png';
import { Event, gaCategory, gaAction, gaLabel } from '../../utils/analytics';

import { AppBar, Toolbar, Button, Drawer, List, ListItem, IconButton } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';


//const Navbar = ({ auth, logOutUser, history }) => {
const Navbar = () => {

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentMenu, setCurrentMenu] = useState('');
  const history = useHistory();

  const navItems = [
    { label: 'Login', link: '/login' }
  ];


  const handleOpenMenu = (event, label) => {
    setAnchorEl(event.currentTarget);
    setCurrentMenu(label);
    setMenuOpen(true);

    //Event({ category: gaCategory.EXPLORE_INFO, action: gaAction.NAV_MENU_ITEM_CLICK, label: `${gaAction.NAV_MENU_ITEM_CLICK}:${label}` });
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setCurrentMenu('');
    setMenuOpen(false);
  };

  const handleTryDemoActionButtonClick = () => {
    history.push("/demo");
    Event({ category: gaCategory.NAV_BAR, action: gaAction.NAV_DEMO_BUTTON });
  };

  const handleNavigation = (link) => {
    history.push(link);
    setDrawerOpen(false);
  };

  return (
    <Container className="navbar-container" maxWidth={isMobile ? 'xs' : 'xl'}>
      <nav className="navbar">
        <Fab className="logo" variant="extended" style={{ textTransform: 'none', zIndex: 100, borderRadius: '1px' }} onClick={() => history.push('/')}>
          <img src={Logo} style={{ height: '30px' }} />
          {/*<div className="logoTxt">Oroara</div>*/}
        </Fab>

        <div className="nav-links flex-1">
          <AppBar position="static" className="nav-item-bar">
            <Toolbar className="nav-item-toolbar">
              {isMobile ? (
                <>
                  <IconButton className="nav-menu-icon-btn" color="inherit" onClick={() => setDrawerOpen(true)}>
                    <MenuIcon className="nav-menu-icon" />
                  </IconButton>
                  <Drawer anchor="top" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <IconButton onClick={() => setDrawerOpen(false)}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <List>
                      {navItems.map((item, index) => (
                        <React.Fragment key={index}>
                          <ListItem button onClick={() => handleNavigation(item.link)}>
                            {item.icon}
                            <div>{item.label}</div>
                          </ListItem>


                        </React.Fragment>
                      ))}
                    </List>
                  </Drawer>
                </>
              ) : (
                navItems.map((item, index) => (
                  <React.Fragment key={index}>
                    <Button
                      className="nav-item"
                      style={{ textTransform: 'none' }}
                      onClick={() => handleNavigation(item.link)}
                    >
                      {item.icon}
                      <div>{item.label}</div>
                    </Button>

                  </React.Fragment>
                ))
              )}
            </Toolbar>
          </AppBar>
        </div>

        {!isMobile &&
          <Button className="action" variant="extended" style={{ textTransform: 'none', zIndex: 100, borderRadius: '1px' }} onClick={handleTryDemoActionButtonClick}>
            <div>Try Demo</div>
          </Button>}
      </nav>

    </Container>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default compose(withRouter, connect(mapStateToProps, { logOutUser }))(Navbar);
