import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

const ProductFeedbackDialog = ({ open, onClose }) => {
    const [dialogFormData, setDialogFormData] = useState({
        feedbackContent: ''
    });
    const [submitDialogStatus, setSubmitDialogStatus] = useState(null);

    const handleDialogFormChange = (e) => {
        setDialogFormData({
            ...dialogFormData,
            [e.target.name]: e.target.value
        });
    };

    const handleDialogSubmit = () => {
        emailjs.send('service_5a79la3', 'template_aahwe6w', dialogFormData, '1eJ3ws1YtOIGQ30s4')
            .then(() => {
                setSubmitDialogStatus('success');
            }, (error) => {
                setSubmitDialogStatus('error');
                console.log(error.text);
            });
    };

    return (
        <Dialog open={open} onClose={onClose} disableBackdropClick disableEscapeKeyDown>
            <DialogTitle>{submitDialogStatus === 'success' ? "Thank you for the feedback!" : "Your feedback is important to make the product work for you"}</DialogTitle>
            <DialogContent>
                {submitDialogStatus === 'success' ? (
                    {/*<div>Feedback sent successfully. Thank you!</div>*/}
                ) : (
                    <>
                        <TextField
                            margin="dense"
                            name="feedbackContent"
                            label="Feedback and suggestion"
                            type="text"
                            fullWidth
                            value={dialogFormData.feedbackContent}
                            onChange={handleDialogFormChange}
                        />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                {submitDialogStatus === 'success' ? (
                    <Button onClick={onClose} color="primary">
                        Close
                    </Button>
                ) : (
                    <>
                        <Button onClick={onClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleDialogSubmit} color="primary">
                            Send
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default ProductFeedbackDialog;