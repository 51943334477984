import React from 'react';
import Layout from '../../layout/Layout';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Event, gaCategory, gaAction } from '../../utils/analytics';
import './styles.css';

const About = () => {

  const history = useHistory();

  const handleDemoActionButtonClick = () => {
    history.push('/demo')
    Event({ category: gaCategory.USER_INTEREST, action: gaAction.ABOUT_DEMO_ACTION_BUTTON });
  };

  return (
    <Layout>
      <div className="about-page">
        <div className="about-content">
          <p>
            Automate radio frequency (RF) communication design. Our proprietary AI models can generate and simulate antenna performance. Instead of days and weeks to generate the design and simulation, it now takes minutes, with a low learning curve. No need to install any software. Access your design and simulation on the cloud.
          </p>
          <div className="button-container">
            <Button
              variant="contained"
              className="demo-button"
              style={{ textTransform: 'none' }}
              onClick={handleDemoActionButtonClick}
            >
              Try Demo
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default About;
