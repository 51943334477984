import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Layout from '../../layout/Layout';
import { Event, gaCategory, gaAction, gaLabel } from '../../utils/analytics';

import { Grid, Fab } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import backgroundImage1 from "../../images/home_hero_bg.svg";
import backgroundImage2 from "../../images/radio-wave-bw.png";

import './styles.css';

const Home = ({ auth }) => {

  const history = useHistory();

  const handleMainActionButtonClick = () => {
    history.push('/about')
    Event({ category: gaCategory.USER_INTEREST, action: gaAction.HOME_MAIN_ACTION_BUTTON });
  };

  return (
    <Layout>
      <div
        className='home-page-background'
        style={{
          //position: 'relative', // Allows positioning of elements
          minHeight: '100vh', // Ensure the background covers the full height of the viewport
          width: '100vw', // Ensure it covers the full width
        }}
      >
        {/* Background image 1 without opacity */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${backgroundImage1})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            opacity: 0.5,
            zIndex: 1, // Behind the second background
          }}
        ></div>

        {/* Background image 2 with reduced opacity */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${backgroundImage2})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            opacity: 0.3,
            zIndex: 2, // Above the first background
          }}
        ></div>

        {/* Main content goes here */}
        <div
          className="home-page"
          style={{
            position: 'relative', // Ensures content is above the backgrounds
            zIndex: 3, // Puts the content above both backgrounds
          }}
        >
          <div className="home-page-intro-container">
            <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" md={6} xs={12}>
              <Grid item>
                <h1>AI-assisted RF design and simulation, right on the cloud.</h1>
              </Grid>
              <Grid item>
                <Fab
                  variant="extended"
                  className="home-action-button"
                  style={{
                    textTransform: 'none',
                    zIndex: 100,
                    borderRadius: '1px',
                    color: 'white',
                  }}
                  onClick={handleMainActionButtonClick}
                >
                  Learn more
                  <ArrowForwardIosIcon />
                </Fab>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

//export default compose(connect(mapStateToProps, { reseedDatabase }))(Home);
export default Home;
