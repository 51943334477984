import React from 'react';
import PropTypes from 'prop-types';

import Navbar from '../components/Navbar/Navbar';
import FullScreenImage from "../components/FullScreenImage/FullScreenImage";
import './styles.css';

import { isMobile } from "react-device-detect";

const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
      <div className="container">{children}</div>
      {/*<FullScreenImage />*/}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
