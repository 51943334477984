import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

const PerspectiveCustomerDialog = ({ open, onClose }) => {
    const [dialogFormData, setDialogFormData] = useState({
        description: '',
        name: '',
        contact: ''
    });
    const [submitDialogStatus, setSubmitDialogStatus] = useState(null);

    const handleDialogFormChange = (e) => {
        setDialogFormData({
            ...dialogFormData,
            [e.target.name]: e.target.value
        });
    };

    const handleDialogSubmit = () => {
        emailjs.send('service_5a79la3', 'template_qbyuf7n', dialogFormData, '1eJ3ws1YtOIGQ30s4')
            .then(() => {
                setSubmitDialogStatus('success');
            }, (error) => {
                setSubmitDialogStatus('error');
                console.log(error.text);
            });
    };

    return (
        <Dialog open={open} onClose={onClose} disableBackdropClick disableEscapeKeyDown>
            <DialogTitle>{submitDialogStatus === 'success' ? "Success" : "Interested to try Oroara?"}</DialogTitle>
            <DialogContent>
                {submitDialogStatus === 'success' ? (
                    <div>Message sent successfully! We will get back soon.</div>
                ) : (
                    <>
                        <TextField
                            autoFocus
                            margin="dense"
                            name="name"
                            label="What's your name?"
                            type="text"
                            fullWidth
                            value={dialogFormData.name}
                            onChange={handleDialogFormChange}
                        />
                        <TextField
                            margin="dense"
                            name="description"
                            label="Organization"
                            type="text"
                            fullWidth
                            value={dialogFormData.description}
                            onChange={handleDialogFormChange}
                        />
                        <TextField
                            margin="dense"
                            name="contact"
                            label="What's your email?"
                            type="text"
                            fullWidth
                            value={dialogFormData.contact}
                            onChange={handleDialogFormChange}
                        />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                {submitDialogStatus === 'success' ? (
                    <Button onClick={onClose} color="primary">
                        Close
                    </Button>
                ) : (
                    <>
                        <Button onClick={onClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleDialogSubmit} color="primary">
                            Send
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default PerspectiveCustomerDialog;