import React, { useState, useEffect } from "react";
import rotatingHomeImage1 from "../../images/pexels-igor-mashkov-14869791-6325003.jpg";
import rotatingHomeImage2 from "../../images/spy-sat.jpg";
import './styles.css';

const backgroundImageOptions = [
  /*rotatingHomeImage1*/
  rotatingHomeImage2
];

const FullScreenImage = () => {

  const [image, setImage] = useState(null);

  useEffect(() => {
    const randomImage = backgroundImageOptions[Math.floor(Math.random() * backgroundImageOptions.length)];
    setImage(randomImage);
  }, []); // The empty dependency array means this effect will only run once, similar to componentDidMount.

  return (
    <div id="large-img-container">
      <img alt="thismake ai" src={image} />
    </div>
  );
}

export default FullScreenImage;